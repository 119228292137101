import React from 'react';
import { Link } from 'gatsby';
import DocumentationSection from '../../../containers/SaasModern/Documentation';

const Documentation = () => {
  const content = (
    <div>
      <ul style={{ marginTop: 20 }}>
        <li>
          <Link to="/Documentation/How-To/Install-PrintableCal">
            Install PrintableCal
          </Link>
        </li>
        <li>
          <Link to="/Documentation/How-To/Uninstall-PrintableCal">
            Uninstall PrintableCal
          </Link>
        </li>
        <li>
          <Link to="/Documentation/How-To/Customize-a-Template">
            Customize a Template
          </Link>
        </li>
      </ul>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/How-To"
      commentsId="commentsplus_post_158_489"
      title="How To..."
      description="PrintableCal is an add-in for Microsoft Excel and Word. It enables printing of calendars, using a variety of customizable calendar templates and data sources."
      keywords="Excel calendar, Word calendar, calendar add-in, create printable calendar, print holidays, print Outlook, calendar legend, custom calendar, calendar template, 2020 calendar"
      content={content}
    />
  );
};

export default Documentation;
